import React from "react"
import styled from "styled-components"

const FuboBanner = () => {
  return (
    <StyledFuboBanner>
      <div className="FuboBanner--logo">
        <img
          src="https://assets-imgx.fubo.tv/marketing/lp/images/fsn/Fubo_Logo_HorizontalApp_rgb.png?w=300&ch=width&auto=format,compress"
          alt="Fubo Logo"
        />
      </div>
      <div className="FuboBanner--text">
        Fubo Sports is also available on the Fubo streaming app with a paid
        subscription. Watch 200+ live TV & sports channels without cable.{" "}
        <a href="https://www.fubo.tv/" target="_blank">
          Learn more
        </a>
      </div>
    </StyledFuboBanner>
  )
}

export default FuboBanner

const StyledFuboBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 36px;
  max-width: 800px;
  background-color: rgba(217, 217, 217, 0.5);
  padding: 25px 39px;
  border-radius: 14px;
  color: #000;
  font-weight: 600;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .FuboBanner--logo {
    max-width: 100px;
    img {
      max-width: 100%;
      display: block;
    }
  }
  .FuboBanner--text {
  }
`
